import React, { useState } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Modal,
  Button,
  Divider,
  Radio,
  RadioGroup,
} from "rsuite";

import CountryFilter from "../Filters/CountryFilter";
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import ServiceTypes from "../Filters/ServiceTypes";
import CurrencyFilter from "../Filters/CurrencyFilter";
import CourierFilter from "../Filters/CourierFilter";
import WarehouseFilter from "../Filters/WarehouseFilter";
import ProductFilter from "../Filters/ProductFilter";

const Quotation = ({ createServiceQuotation }) => {
  const [show, openModal] = useState(false);
  const [businessclient, setBusinesclient] = useState("");
  const [supplier, setSupplier] = useState("");
  const [country, setCountry] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [currency, setCurrency] = useState("");
  const [serviceType, setServicetype] = useState("");
  const [courier, setCourier] = useState("");

  const [formValue, setFormValue] = useState({
    product: "",
    name: "",
    email: "",
    password: "",
    radioClient: "client",
    radio: "",
    billingType: "fixed",
    rate: "",
    rate_costSource: "",
    amount: "",
    "weight_kg.from": null,
    "weight_kg.to": null,
    quantity_dependency:null
  });

  const handleCreateQuotation = () => {
    createServiceQuotation({
      product: formValue.product,
      businessclient,
      supplier,
      country,
      currency,
      serviceType,
      courier,
      warehouse,
      rate: formValue.rate / 100,
      rate_costSource: formValue.rate_costSource,
      amount: formValue.amount,
      "weight_kg.from": formValue["weight_kg.from"],
      "weight_kg.to": formValue["weight_kg.to"],
      quantity_dependency:formValue.quantity_dependency
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => openModal(false)}
        style={{ maxWidth: "100%" }}
      >
        <Modal.Header>
          <Modal.Title>NEW Quotation</Modal.Title>
        </Modal.Header>
        <Divider></Divider>
        <Modal.Body>
          <Form
            formValue={formValue}
            onChange={(formValue) => {
              setFormValue(formValue);
            }}
          >
            <ControlLabel>Quotations Type</ControlLabel>
            <RadioGroup
              inline
              name="radioClient"
              value={formValue.radioClient}
              onChange={(value) => {
                setFormValue({ ...formValue, radioClient: value });
              }}
            >
              <Radio name="client" value="client">
                Client Specific
              </Radio>
              <Radio
                checked={formValue.radioClient === "standard"}
                name="standard"
                value="standard"
              >
                Standard (All)
              </Radio>
            </RadioGroup>

            {formValue.radioClient === "client" && (
              <FormGroup>
                <ControlLabel>Client</ControlLabel>

                <BusinessclientFilter
                  fluid={true}
                  useFilter={false}
                  name="businessclient"
                  value={businessclient}
                  handleChange={(value) => setBusinesclient(value)}
                />
              </FormGroup>
            )}
            <FormGroup>
              <FormGroup>
                <ControlLabel>Service Catergory</ControlLabel>

                <RadioGroup
                  inline
                  name="radio"
                  value={formValue.radio}
                  onChange={(value) => {
                    setFormValue({ ...formValue, radio: value });
                    setCourier("");
                  }}
                >
                  <Radio name="warehouse" value="warehouse">
                    Warehouse Operations
                  </Radio>
                  <Radio name="shipping" value="shipping">
                    Shipping Operations
                  </Radio>
                  <Radio name="finance" value="finance">
                    Finance
                  </Radio>
                  <Radio name="finance" value="goods">
                    Goods
                  </Radio>
                  <Radio name="finance" value="callcenter">
                    Call Center
                  </Radio>
                </RadioGroup>

                {["shipping", "finance"].includes(formValue.radio) && (
                  <FormGroup>
                    <ControlLabel>Country</ControlLabel>
                    <CountryFilter
                      fluid={true}
                      useFilter={false}
                      name="county"
                      value={country}
                      handleChange={(value) => setCountry(value)}
                      multiple={false}
                    />
                  </FormGroup>
                )}
                {/* {   formValue.radio==='goods'  && */}
                <FormGroup>
                  <HelpBlock tooltip>
                    Service / Product supplier. Useful if billing will be
                    separated.
                  </HelpBlock>

                  <ControlLabel>Supplier</ControlLabel>

                  <BusinessclientFilter
                    useFilter={false}
                    fluid={true}
                    name="supplier"
                    value={supplier}
                    handleChange={(value) => setSupplier(value)}
                  />
                </FormGroup>
                {/* } */}

                {["warehouse"].includes(formValue.radio) && (
                  <FormGroup>
                    <ControlLabel>Product</ControlLabel>
                    <ProductFilter
                      disabled={!businessclient}
                      businessclient={businessclient}
                      fluid={true}
                      useFilter={false}
                      value={formValue.product}
                      handleChange={(value) => {
                        setFormValue({ ...formValue, product: value });
                      }}
                      multiple={false}
                    />
                  </FormGroup>
                )}

                {formValue.radio === "shipping" && (
                  <FormGroup>
                    <ControlLabel>Courier</ControlLabel>
                    <CourierFilter
                      country={country}
                      useFilter={false}
                      fluid={true}
                      multiple={false}
                      handleChange={(value) => setCourier(value)}
                      value={courier}
                    ></CourierFilter>
                  </FormGroup>
                )}
                {formValue.radio === "warehouse" && (
                  <FormGroup>
                    <ControlLabel>Warehouse</ControlLabel>
                    <WarehouseFilter
                      warehouse={warehouse}
                      fluid={true}
                      multiple={false}
                      handleChange={(value) => setWarehouse(value)}
                      value={warehouse}
                      useFilter={false}
                    ></WarehouseFilter>
                  </FormGroup>
                )}
              </FormGroup>

              {/* {formValue.radio==='callcenter'&&  */}
              <FormGroup>
                <ControlLabel>Billting type</ControlLabel>
                <RadioGroup
                  inline
                  name="billingType"
                  value={formValue.billingType}
                  onChange={(value) => {
                    setFormValue({ ...formValue, billingType: value });
                  }}
                >
                  <Radio name="fixed" value="fixed">
                    Fixed
                  </Radio>
                  <Radio name="relative" value="relative">
                    Relative
                  </Radio>
                  <Radio name="recurring" value="recurring">
                    Recurring
                  </Radio>
                </RadioGroup>
              </FormGroup>
              {/* } */}

              <ControlLabel>Service Name</ControlLabel>
              <ServiceTypes
                
                fluid={true}
                handleChange={(value) => {
                  setServicetype(value);
                }}
                style={{maxWidth:'300px'}}
                value={serviceType}
                multiple={false}
                category={formValue.radio}
                billing_type={formValue.billingType}
                disabled={!formValue.radio}
              />
            </FormGroup>

            <Divider>Labour control</Divider>
            <div className="flex--left">
              <FormControl
                // disabled={!formValue.radio}
                type="number"
                name="quantity_dependency"
                placeholder="units in package..."
                style={{ width: 140, marginRight: "10px" }}
              />
             
            </div>

            <Divider>Billing - service price</Divider>

            {formValue.billingType !== "relative" && (
              <div style={{ display: "flex" }}>
                <FormControl
                  disabled={!formValue.radio}
                  type="number"
                  name="amount"
                  placeholder="amount netto"
                  style={{ width: 140, marginRight: "10px" }}
                />
                <CurrencyFilter
                  fluid={false}
                  handleChange={(value) => setCurrency(value)}
                  value={currency}
                  multiple={false}
                  disabled={!formValue.radio}
                />
                <HelpBlock tooltip>
                  Rate will be applyed as follows: Quantity x Rate.
                </HelpBlock>
              </div>
            )}

            {formValue.billingType === "relative" && (
              <div className="">
                <ControlLabel>Rate (%)</ControlLabel>
                <div className="flex--left">
                  <FormControl
                    name="rate"
                    type="number"
                    style={{ width: 80, marginLeft: "12px" }}
                  />

                  <CurrencyFilter
                    fluid={false}
                    handleChange={(value) => setCurrency(value)}
                    value={currency}
                    multiple={false}
                    disabled={!formValue.radio}
                    style={{ marginLeft: "10px" }}
                  />
                </div>

                <ControlLabel>Rate sorce</ControlLabel>
                <HelpBlock tooltip>
                  Rate will be applyed to Cost Basis per Service Trigger.
                </HelpBlock>

                <div>
                  <FormControl
                    name="rate_costSource"
                    type="text"
                    style={{ width: 80, marginLeft: "12px" }}
                  />
                </div>
              </div>
            )}

            <Divider>Weight (kg)</Divider>
            <div className="flex--left">
              <FormControl
                disabled={!formValue.radio}
                type="number"
                name="weight_kg.from"
                placeholder="kg from..."
                style={{ width: 140, marginRight: "10px" }}
              />
              <FormControl
                disabled={!formValue.radio}
                type="number"
                name="weight_kg.to"
                placeholder="kg to..."
                style={{ width: 140, marginRight: "10px" }}
              />
            </div>
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleCreateQuotation();
              // openModal(false);
            }}
            appearance="primary"
          >
            Confirm
          </Button>
          <Button onClick={() => openModal(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Button onClick={() => openModal(true)}>Add Quotation</Button>
    </div>
  );
};

export default Quotation;
