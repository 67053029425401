import React, { useEffect, useState } from "react";

import { Alert } from "rsuite";
import { Button, Segment, Header, Divider } from "semantic-ui-react";

import {
  getPos,
  addPosShipment,
  deletePo,
  updatePo,
  updateGR,
} from "../../../actions/pos";

import CanViewPage from "../../../role-based-access/CanViewPage";
import ItemsPerPage from "../../ItemsPerPage";
import { PoTable } from "./PoTable";
import Filters from "./filters";
import StatusTabs from "./StatusTabs";
import { useUpdateContext } from "../../../context/UpdateContext";

import "./polist.scss";

const ListPOs = (props) => {
  const {
    businessclient,
    supplier,
    product,
    handleDateToChange,
    handleSupplierChange,
    warehouse,
  } = useUpdateContext();

  const [state, setState] = useState({
    selected: [],
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrenPage] = useState(1);

  const [dateTo, setDateTo] = useState();
  const [dateFrom, setDateFrom] = useState();

  const [pos, setPos] = useState({
    list: [],
    counts: [],
  });
  const [status, setStatus] = useState("New");
  const [isLoading, setIsloading] = useState(true);

  const handleSelectAll = (headerIsChecked) => {
    if (headerIsChecked) {
      const items = props.pos
        .filter((item) => item.status === "New")
        .map((item) => item._id);
      setState({ ...state, selected: items });
    } else {
      setState({ ...state, selected: [] });
    }
  };

  const handleDeletePO = (id) => {
    const { history } = props;
    setIsloading(true);
    deletePo({ history, id })
      .then((resp) =>
        getPos({
          itemsPerPage,
          currentPage,
          businessclient,
          supplier,
          product,
          status,
          dateTo,
          dateFrom,
          warehouse,
        })
          .then((data) => {
            setPos(data);
            setIsloading(false);
          })
          .catch((error) => {
            Alert.error(error);
            setIsloading(false);
          })
      )
      .catch((err) => {
        setIsloading(false);
        Alert.error(err);
      });
  };

  const handleSelected = (id, checked) => {
    let items = [...state.selected];
    if (checked) {
      items = [...state.selected, id];
    } else {
      for (let i = 0; i < items.length; i++) {
        if (id === items[i]) {
          items.splice(i, 1);
        }
      }
    }
    setState({ selected: items });
  };

  const handleCreateShipment = () => {
    const { selected } = state;
    addPosShipment({ pos: selected, history }).then((resp) =>
      console.log("add po to shipment response")
    );
  };

  useEffect(() => {
    setIsloading(true);
    getPos({
      itemsPerPage,
      currentPage,
      businessclient,
      supplier,
      product,
      status,
      dateTo,
      dateFrom,
      warehouse
    })
      .then((data) => {
        setPos(data);
        setIsloading(false);
      })
      .catch((error) => {
        Alert.error(error);
        setIsloading(false);
      });
  }, [
    itemsPerPage,
    status,
    currentPage,
    businessclient,
    supplier,
    product,
    dateTo,
    dateFrom,
    warehouse
  ]);

  const { history } = props;

  const { selected } = state;

  // return (<div>Hello</div>)
  return (
    <CanViewPage path="/pos">
      <div style={{ maxWidth: "1200px", margin: "20px" }}>
        <div className="flex--between">
          <Header as="h3">Purchase Orders</Header>

          <div>
            <Button
              disabled={selected.length === 0}
              onClick={handleCreateShipment}
            >
              {`Create Shipment ${
                selected.length > 0 ? `( x ${selected.length} )` : ""
              }`}
            </Button>
            <Button positive onClick={() => history.push(`/pos/create/new`)}>
              +CREATE
            </Button>
          </div>
        </div>
        <Divider />
        <Filters
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          handleDateToChange={handleDateToChange}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrenPage={setCurrenPage}
          setItemsPerPage={setItemsPerPage}
          handleSupplierChange={handleSupplierChange}
        />

        <Segment loading={isLoading}>
          <div className="flex--between vertical--top mb--1">
            <StatusTabs
              handleClick={(status) => setStatus(status)}
              counts={pos.counts}
              status={status}
            />
            <ItemsPerPage
              value={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
          <PoTable
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handleSelectAll={handleSelectAll}
            selected={selected}
            loading={isLoading}
            history={history}
            pos={pos.list}
            handleSelected={handleSelected}
            handleDeletePO={handleDeletePO}
            updatePo={updatePo}
            updateGR={updateGR}
          />

          {/* {pos.length > 0 && total > itemsPerPage && (
            <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(total / itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={(e,{activePage})=>setCurrenPage(activePage)}
            />
          )} */}
        </Segment>
      </div>
    </CanViewPage>
  );
};

export default ListPOs;
