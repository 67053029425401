import { apiCall } from "../services/api";
import {Alert} from 'rsuite'


export const updateWarehouse = ({ id, updates }) =>{
    const url = `${process.env.REACT_APP_API}/warehouse/${id}`;

return apiCall("put", url, updates)
      .then((resp) => resp)
      .catch((err) => Alert.error(err.message))
  }
  

  export const getWarehouses = ({searchTerm,warehouse,scope}) => {
  
   const url = `${process.env.REACT_APP_API}/warehouse?searchTerm=${searchTerm}&warehouse=${warehouse}&scope=${scope}`;
   return apiCall("get", url)
      .then((resp) => resp)
      .catch((err) => Alert.error(err.message))
  };

  export const createWarehouse = (body) =>  {
    const url = `${process.env.REACT_APP_API}/warehouse`;
    return apiCall("post", url, body)
      .then((resp) => resp)
      .catch((err) => Alert.error(err.message))
  };
  
  export const getBalanceAtDate = (body) =>  {
    const url = `${process.env.REACT_APP_API}/warehouse/balanceatdate`;
    return apiCall("post", url, body)
      .then((resp) => resp)
      .catch((err) => Alert.error(err.message))
  };
  

  export const updateSetting=({id,body})=>{
    const url = `${process.env.REACT_APP_API}/settings/warehouse/${id}`;
    return apiCall("post", url, body)
      .then((resp) => resp)
      .catch((err) => Alert.error(err.message))
  }

  export const getSettings=(warehouse)=>{
    const url = `${process.env.REACT_APP_API}/settings/warehouse/${warehouse}`;
    return apiCall("get", url)
      .then((resp) => resp)
      .catch((err) => Alert.error(err.message))
  }