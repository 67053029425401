import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";

import { getWarehouses } from "../../actions/warehouses";
import { useUpdateContext } from "../../context/UpdateContext";

function WarehouseFilter({
  name = "warehouse",
  value,
  style = {
    margin: "2px",
    minWidth: "200px",
    maxWidth: "220px",
    border: "none !important",
    color: "#3F72AF !important",
  },
  handleChange,
  multiple = true,
  fluid = false,
  selection = true,
  error = false,
  clearable = true,
  closeOnChange = true,
  selectOnBlur = false,
  disabled = false,
  placeholder = "warehouse...",
  options = [],
  useFilter = true,
  scope ='orders',
}) {
  const [searchTerm, setSearch] = useState();
  const [list, setList] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const {
    handleWarehouseChange,
    warehouse,
    shipTo,
    handleShipToChange,
    setDropList,
  } = useUpdateContext();

  useEffect(() => {
    if (Array.isArray(localStorage.warehouseList) && !searchTerm) {
      const list = JSON.parse(localStorage.getItem("warehouseList") || "[]");
      // setDropList({ name: "businessclients", list });
      setList(
        list.map((item) => ({
          value: item._id,
          text: item.name,
          key: item._id,
        }))
      );
    } else {
      setIsloading(true);
      getWarehouses({ searchTerm, scope })
        .then((list) => {
          setIsloading(false);
          setDropList({ name: "warehouseList", list: JSON.stringify(list) });
          setList(
            list.map((item) => ({
              value: item._id,
              text: item.name,
              key: item._id,
            }))
          );
        })

        .catch((error) => alert(error.message));
    }
  }, [searchTerm]);

  return (
    <Dropdown
      loading={isLoading}
      style={style}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      value={
        useFilter ? getValue({ name, multiple, warehouse, shipTo }) : value
      }
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable={clearable}
      selection={selection}
      search
      selectOnBlur={selectOnBlur}
      closeOnChange={closeOnChange}
      onChange={(e, { value }) => {
        if (useFilter) {
          if (name === "shipTo") handleShipToChange(value);
          else handleWarehouseChange(value);
        } else handleChange(value);
      }}
      options={useFilter ? list : [...options, ...list]}
      onSearchChange={(e, data) => setSearch(data.searchQuery)}
    />
  );
}
function getValue({ name, multiple, warehouse, shipTo, _id }) {
  let val = warehouse;

  if (name === "shipTo") val = shipTo;

  let value =
    multiple && Array.isArray(val)
      ? val
      : multiple && !Array.isArray(val)
      ? [val]
      : !multiple && Array.isArray(val)
      ? val
      : val;

  return value;
}

export default WarehouseFilter;
