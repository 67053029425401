import React from "react";
import { Table, Header, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import EditableText from "../../EditableText";

import { useUpdateContext } from "../../../context/UpdateContext";


function Nogoodstable({ data }) {
  

  const { handleAddToPO } = useUpdateContext();


  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={5}>Product name</Table.HeaderCell>
      <Table.HeaderCell width={3}>No Goods Quantity</Table.HeaderCell>
      <Table.HeaderCell width={3}>In tranzit</Table.HeaderCell>
      <Table.HeaderCell width={3}>Add To Card</Table.HeaderCell>

      {/* <Table.HeaderCell textAlign="right">Action</Table.HeaderCell> */}
    </Table.Row>
  );

  const rows = data.products.map((product, i) => {
    return (
      <Table.Row key={product._id}>
        <Table.Cell> {product.name[0]}</Table.Cell>
        <Table.Cell> {product.quantity}</Table.Cell>
        <Table.Cell>
          {/* {shipping.quantity}  */}
          {product.tranzit &&
            product.tranzit.poId.map((po) => (
              <div>
                <Link to={`/pos/${po}`} target="_blank">
                  {product.tranzit.total} View PO
                </Link>
              </div>
            ))}
        </Table.Cell>
        <Table.Cell textAlign="left">
          <div>
            <EditableText
              style={{width:"100px"}}
              fluid={false}
              name="quantity_shipped"
              value={product.quantity}
              handleChange={(e, { value, name }) => {}}
              showButton={true}
              handleAdd={(value) =>
                handleAddToPO({
                  quantity: value,
                  product: {
                    name: product.name[0],
                    sku: product.sku[0],
                    _id: product.id,
                  },
                  warehouse: {
                    _id: data.warehouse[0]._id,
                    name: data.warehouse[0].name,
                  },
                  businessclient: {
                    _id: data.client[0]._id,
                    name: data.client[0].name,
                  },
                })
              }
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Segment primary color="yellow">
      <Header>
        {data.warehouse[0].name}
        <div className="smallinfo"> {data.client[0].name}</div>
      </Header>
      <Table basic="very" columns={5}>
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    </Segment>
  );
}

export default Nogoodstable;
