import React, { useState } from "react";
import { Button, Table, Header } from "semantic-ui-react";
import { Drawer,Alert } from "rsuite";
import EditableText from "../EditableText";
import { createPOfromCard } from "../../actions/pos";
import { Link } from "react-router-dom";
import { useUpdateContext } from "../../context/UpdateContext";

// const data = [
//   {
//     product: { sku: "5555_cC", name: "test_color_size - L - B" },
//     quantity: 30,
//     warehouse: { _id: "ewqr432fsafasd535", name: "Bulgary depo" },
//     businessclient: { _id: "5df9f1732d9d10847d35ab96", name: "Express colet" },
//   },
//   {
//     product: { sku: "34124234", name: "product 2" },
//     quantity: 230,
//     warehouse: { _id: "ewqr432fsafasd535", name: "Spain" },
//     businessclient: { _id: "5e2b7cd2d3a3c7b89845e301", name: "Max Delivery" },
//   },
// ];

// localStorage.setItem("PO-shopping", JSON.stringify(data));

const Shoppingcard = (props) => {

  const { list, setList, handleRemoveItem, handleUpateQuantity } =useUpdateContext();

  const [showCard, setShowCard] = useState(false);
  const [loading, setLoading] = useState(false);



  const handleOrderGoods = () => {
    setLoading(true);
    const list = JSON.parse(localStorage.getItem("PO-shopping"));

    createPOfromCard(list)
      .then((resp) => {
        setShowCard(false)
        setList([]);
        localStorage.setItem("PO-shopping", []);
        setLoading(false)
        props.history.push('/pos')
    })
      .catch((error) => {
        setLoading(false)
        Alert.error(error)});
  };

  return (
    <div style={{ marginRight: "20px" }}>
      {list.length === 0 && (
        <Button circular icon="shop" onClick={() => setShowCard(true)}></Button>
      )}
      {list.length > 0 && (
        <Button circular icon="shop" onClick={() => setShowCard(true)}>
          PO Card Items-{list.length}
        </Button>
      )}

      <Drawer show={showCard} onHide={() => setShowCard(false)} size="sm">
        <Drawer.Header>
          <Drawer.Title>PO SHOPPING CARD</Drawer.Title>
        </Drawer.Header>
        {/* <Divider /> */}
        <Drawer.Body>
          {!loading && list.length > 0 && (
            <Table celled stackable>
              <Table.Header>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>Quantity</Table.Cell>
                <Table.Cell textAlign="left">Ship To</Table.Cell>
                <Table.Cell textAlign="left">Action</Table.Cell>
              </Table.Header>
              <Table.Body>
                {list.map((item, i) => {
                  return (
                    <Table.Row>
                      <Table.Cell textAlign="left">
                        <Header as="h3">
                          {item.product.name}
                          <Header.Subheader>
                            {item.businessclient.name}
                          </Header.Subheader>
                        </Header>
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <EditableText
                          style={{width:"50px"}}
                          name="quantity"
                          value={item.quantity}
                          handleChange={(e, { value, name }) => {
                            handleUpateQuantity({ quantity: value, i });
                          }}
                        />

                        {/* {item.quantity} */}
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <Header as="h3">{item.warehouse.name}</Header>
                      </Table.Cell>
                      <Table.Cell textAlign="left" width={1}>
                        <Button
                          onClick={() => handleRemoveItem(i)}
                          size="tiny"
                          circular
                          basic
                          icon="delete"
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
          {loading && <div>Loading...</div>}
          {list.length === 0 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Header as="h4">Start Shopping in:</Header>
              <Link to="/reports/nogoods" onClick={() => setShowCard(false)}>
                1. No Goods Report
              </Link>
              <Link to="/reports/leadstock" onClick={() => setShowCard(false)}>
                2. Lead-Stock report
              </Link>
            </div>
          )}
        </Drawer.Body>

        <Drawer.Footer>
          <div className="mb--1">
            <Button onClick={() => setShowCard(false)}>Close</Button>
            <Button
              disabled={list.length === 0}
              color="green"
              onClick={() => handleOrderGoods()}
            >
              Order Goods
            </Button>
          </div>
        </Drawer.Footer>
      </Drawer>
    </div>
  );
};

export default Shoppingcard;
