import React from "react";
import {
  Table,
  Button,
  Label,
  Divider,
  Header,
  Loader
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ItemsPerPage from "../../ItemsPerPage";

export const statusColor = {
  New: "red",
  Done: "green",
  processing: "orange",
  Shipping: "blue",
  Delivered: "green"
};

const ships_headers = (
  <Table.Header>
    <Table.Row>
      {/* < Checkbox></Checkbox> */}
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>ID</Table.HeaderCell>
      {/* <Table.HeaderCell>Created</Table.HeaderCell> */}
      <Table.HeaderCell>Warehouse</Table.HeaderCell>
      <Table.HeaderCell width={2}>Client</Table.HeaderCell>
      <Table.HeaderCell width={2}>Seller</Table.HeaderCell>
      <Table.HeaderCell>Details</Table.HeaderCell>
      <Table.HeaderCell>Transporter</Table.HeaderCell>
      <Table.HeaderCell width={2}>Dates</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

export const ShipmentTable = ({
  shipments,
  deleteShipment,
  currentPage,
  itemsPerPage,
  total,
  isLoading,
  setItemsPerPage
}) => {
  // if (shipments.length===0){return (<div>No shipments.</div>)}

  const shipments_list = shipments.map((shipment, index) => {
    return (
      <tr key={index}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + index + 1}</Table.Cell>
        <Table.Cell>
          <Link to={`/shipments/inbound/${shipment._id}`} target="_blank">
            {shipment.numericId?shipment.numericId:shipment._id.substr(-9)}
          </Link>
        </Table.Cell>
        {/* <Table.Cell > <Moment format="DD-MM-YY HH:mm">{shipment.createdAt}</Moment></Table.Cell> */}
        <Table.Cell>
          {shipment.warehouse ? shipment.warehouse.name : "NA"}
        </Table.Cell>
        <Table.Cell>
          {" "}
          {shipment.client ? shipment.client.name : "NA"}{" "}
        </Table.Cell>
        <Table.Cell>
          {shipment.seller ? shipment.seller.name : "NA"}
          <br></br>
        </Table.Cell>

        <Table.Cell>
          Packs: {shipment.nrOfPacks}
          <br></br>
          Weight(kg):{shipment.weightKg}
        </Table.Cell>

        <Table.Cell>{shipment.transporter}</Table.Cell>
        <Table.Cell>
          <div className="smallinfox">
            Created:
            <br></br>
            <Moment format="DD-MM-YY HH:mm">{shipment.createdAt}</Moment></div>
          {/* <br></br>
          Updated:<Moment format="DD-MM-YY HH:mm">{shipment.updatedAt}</Moment> */}
        </Table.Cell>
        <Table.Cell>
          <Label color={statusColor[shipment.status]}>{shipment.status}</Label>
        </Table.Cell>
        <Table.Cell>
          {shipment.status !== "Done" && (
            <Button
              icon="trash alternate outline"
              // color = 'grey'
              // id = 'itemlistrow_action'
              basic
              onClick={() => deleteShipment(shipment._id)}
            ></Button>
          )}
        </Table.Cell>
      </tr>
    );
  });

  return (
    <section>
      <div className="flex--between">
        <div></div>
        <ItemsPerPage
          total={total}
          value={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      </div>

      <Divider></Divider>
      {isLoading && (
        <div style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Loader active inline="centered" />
        </div>
      )}

      {!shipments.length && !isLoading && (
        <Header
          style={{ paddingTop: "2em", paddingBottom: "1em" }}
          as="h4"
          textAlign="center"
          color="grey"
        >
          No Shipments Found
        </Header>
      )}

      {shipments.length > 0 && !isLoading && (
        <div className="table-wrapper--horizontal-scroll">
          <Table basic="very" unstackable selectable>
            {ships_headers}
            <tbody>{shipments_list}</tbody>
          </Table>
        </div>
      )}
    </section>
  );
};
