import React from "react";

import { DatePicker } from "rsuite";

import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";
// import ShiptoaddressFilter from '../../Filters/ShipToAddress'

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};

const Filters = ({
  dateFrom,
  dateTo,
  handleDateFromChange,
  handleDateToChange,
  handleShipToChange
}) => {
  return (
    <div>
      <BusinessclientFilter
        style={itemStyle}
        className="po-filters__filter"
        name="businessclient"
      />

      <WarehouseFilter
        scope='all'
        style={itemStyle}
        name="shipFrom"
        placeholder="Ship FROM ware..."
      />
      <WarehouseFilter
        style={itemStyle}
        name="shipTo"
        placeholder="Ship To ware..."
        useFilter={false}
        handleChange={((value)=>{handleShipToChange(value)})}
      />

      <div className="flex--left">
        <div style={{ zIndex: 0 }}>
          <DatePicker
            oneTap
            value={dateFrom}
            isClearable={true}
            format="DD-MM-YYYY"
            onChange={(date) => handleDateFromChange(date)}
            placeholder="from..."
            style={itemStyle}
          />
        </div>
        <div style={{ marginRight: "0", zIndex: 0 }}>
          <DatePicker
            // disabled={isLoading}
            oneTap
            value={dateTo}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => handleDateToChange(date)}
            placeholder="to..."
            style={itemStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
