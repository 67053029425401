import React, { useEffect, useState } from "react";
import { Header, Segment, Divider } from "semantic-ui-react";

import Filters from "./Filters";

import { getStockTurnover } from "../../../actions/reports";

import ExportButton from "./ExportButton";

// import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";

import "./services.scss";
import Turnovertable from "./Turnovertable";
import { useUpdateContext } from "../../../context/UpdateContext";

function Stocksturnover({ showLowStocks = false }) {
  const { product, lowStock_warehouse_view, setLowStockProductView } =
    useUpdateContext();

  const [days, setDays] = useState(7);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [stocks, setStocks] = useState({});
  const [total, setTotal] = useState(0);
  const [warehouse, setWarehouse] = useState();
  const [businessclient, setClient] = useState();

  useEffect(() => {
    setLoading(true);
    getStockTurnover({
      businessclient,
      warehouse,
      product,
      days,
      lowstockproducts: showLowStocks ? lowStock_warehouse_view : [],
    }).then((resp) => {
      setStocks(resp.table);
      setLowStockProductView(resp.total_product);
      setLoading(false);
    });
  }, [businessclient, warehouse, product, days, lowStock_warehouse_view]);

  return (
    // <CanViewPage path="/reports/turnover">
    <main className="page-wrapper">
      <div className="flex--between">
        <Header style={{ marginBottom: 0 }}>Turnover</Header>

        <ExportButton stocks={stocks} />
      </div>
      <Divider></Divider>

      <Filters
        setDays={setDays}
        days={days}
        setWarehouse={(value) => setWarehouse(value)}
        setClient={(value) => setClient(value)}
      />
      <Segment>
        <Turnovertable
          stocks={stocks}
          isLoading={isLoading}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          value={days}
          style={{ marginLeft: "10px" }}

          // handleChangeDays={handleFilterChange}
        />
        {total > itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            total={total}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        )}
      </Segment>
    </main>
    // {/* </CanViewPage> */}
  );
}

export default Stocksturnover;
