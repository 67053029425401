import React from "react";
import EditableText from "../../EditableText";
import { Table } from "semantic-ui-react";

function ProductInfoGrid(props) {
  const { handleFormChange, productForm } = props;
  return (
    <div className="flex--left">
      <div style={{ width: "400px", margin: "5px" }}>
        <Table stackable compact>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                placeholder={"name..."}
                value={productForm.name}
                name={"name"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Sku</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                placeholder={"SKU..."}
                value={productForm.codes.sku}
                name={"codes.sku"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Barcode</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                placeholder={"barcode..."}
                value={productForm.codes.barcode}
                name={"codes.barcode"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Numeric</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                placeholder={"numeric..."}
                value={productForm.codes.numCode}
                name={"codes.numCode"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Client id</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                maxWidth="350px"
                placeholder={"client id..."}
                value={productForm.codes.client_id}
                name={"codes.client_id"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Color</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                placeholder={"color..."}
                value={productForm.color}
                name={"color"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Size</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                placeholder={"size..."}
                value={productForm.size}
                name={"size"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
        </Table>
      </div>

      <div style={{ width: "250px", margin: "5px" }}>
        <Table compact>
          <Table.Row>
            <Table.Cell>Height</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"height..."}
                value={
                  productForm.proprities && productForm.proprities.dimentions
                    ? productForm.proprities.dimentions.height
                    : ""
                }
                name={"proprities.dimentions.height"}
                handleChange={handleFormChange}
                transparentBackground={true}
                // maxWidth={"5em"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Length</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"lenght..."}
                value={
                  productForm.proprities && productForm.proprities.dimentions
                    ? productForm.proprities.dimentions.lenght
                    : ""
                }
                name={"proprities.dimentions.lenght"}
                handleChange={handleFormChange}
                transparentBackground={true}
                // maxWidth={"5em"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Width</Table.Cell>
            <Table.Cell>
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"width..."}
                value={
                  productForm.proprities && productForm.proprities.dimentions
                    ? productForm.proprities.dimentions.width
                    : ""
                }
                name={"proprities.dimentions.width"}
                handleChange={handleFormChange}
                transparentBackground={true}
                // maxWidth={"5em"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Weight(gr)</Table.Cell>
            <Table.Cell>
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"weight..."}
                value={
                  productForm.proprities ? productForm.proprities.weight : ""
                }
                name={"proprities.weight"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
        </Table>
      </div>
      <div style={{ width: "250px", margin: "5px" }}>
        <Table compact>
          <Table.Row>
            <Table.Cell>Box units</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                value={productForm.box_units}
                name={"box_units"}
                handleChange={handleFormChange}
                transparentBackground={true}
                // maxWidth={"5em"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Box Height(cm)</Table.Cell>
            <Table.Cell>
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"height..."}
                value={productForm.box_height}
                name={"box_height"}
                handleChange={handleFormChange}
                transparentBackground={true}
                // maxWidth={"5em"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Box Length(cm)</Table.Cell>
            <Table.Cell>
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"lenght..."}
                value={productForm.box_lenght}
                name={"box_lenght"}
                handleChange={handleFormChange}
                transparentBackground={true}
                // maxWidth={"5em"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Box Width(cm)</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"width..."}
                value={productForm.box_width}
                name={"box_width"}
                handleChange={handleFormChange}
                transparentBackground={true}
                // maxWidth={"5em"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Volume</Table.Cell>
            <Table.Cell>{productForm.volume} (m3)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Weight(kg)</Table.Cell>
            <Table.Cell>
              <EditableText
                style={{
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
                placeholder={"weight..."}
                value={productForm.box_weight ? productForm.box_weight : ""}
                name={"box_weight"}
                handleChange={handleFormChange}
                transparentBackground={true}
              />
            </Table.Cell>
          </Table.Row>
        </Table>
      </div>
    </div>
  );
}

export default ProductInfoGrid;
