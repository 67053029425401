import React from "react";
import { Button, Dropdown } from "semantic-ui-react";

const orderStatusesAll = ["Delivered", "Expired"];

const orderStatuses = [
  {
    text: "All",
    status: "All",
    value: "total",
  },

  {
    text: "New",
    status: "New",
    value: "newPos",
  },

  {
    text: "Pending",
    status: "pending",
    value: "pending",
  },

  {
    text: "Goods Blocked",
    status: "Goods Blocked",
    value: "goodsBlocked",
  },
  // {
  //   text: "No goods",
  //   status: "No goods",
  //   value:"noGoods"
  // },
  {
    text: "Shipping",
    status: "Shipping",
    value: "shipping",
  },
];

function StatusTabs({ handleClick, counts, status }) {
  // console.log(status);

  return (
    <div className="flex--between">
      <ul className="status-nav">
        {counts.map((item, i) => (
          <li
            key={i}
            className={`status-nav__item   ${
              status === item[0] ? "status-nav__item_active" : null
            }`}
            onClick={() => handleClick(item[0])}
          >
            {item[0]} ({item[1]})
            {/* <span className="span-text_tabs">
              ({totals[status === "" ? "All" : status]})
            </span> */}
          </li>
        ))}

        <li style={{ listStyleType: "none", marginTop: "2px" }}>
          <Dropdown
            style={{ minWidth: "7em" }}
            className={`status-nav__item ${
              !orderStatuses.map(({ status }) => status).includes(status)
                ? "status-nav__item_active"
                : ""
            }`}
            placeholder="other..."
            name="status"
            search
            // clearable
            closeOnBlur
            closeOnChange
            // selection
            value={
              orderStatuses.map(({ status }) => status).includes(status)
                ? null
                : status
            }
            onChange={(e, { value }) => handleClick(value)}
            options={orderStatusesAll.map((status, i) => ({
              key: i,
              value: status,
              text: status,
            }))}
          />
        </li>
      </ul>
    </div>
  );
}

export default StatusTabs;
