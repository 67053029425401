import React from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  Message,
  MessageHeader,
} from "semantic-ui-react";
import EditableText from "../../EditableText";

import { useUpdateContext } from "../../../context/UpdateContext";

const Lowstock_per_product = (props) => {
  const { lowStock_product_view, handleAddToPO } = useUpdateContext();

  // console.log('lowstock product view:',lowStock_product_view)
  const view_total_product = [];

  for (let product in lowStock_product_view) {
    const newRow = (
      <TableRow>
        <TableCell>{lowStock_product_view[product].product_name}</TableCell>
        <TableCell>
          {lowStock_product_view[product].total_per_warehouseturnover.map(
            (warehouse) => (
              <TableRow>
                <TableCell>{warehouse.warehouse_name}</TableCell>
                <TableCell>{warehouse.turnover}</TableCell>
              </TableRow>
            )
          )}
        </TableCell>
        <TableCell>{lowStock_product_view[product].total}</TableCell>
        <TableCell>
          <div>
            <EditableText
              // maxWidth={"80px"}
              style={{ minWidth: "80px" }}
              fluid={false}
              name="quantity_shipped"
              value={
                Math.ceil(
                  lowStock_product_view[product].total /
                    (10 * lowStock_product_view[product].total.toString().length)
                ) 
              
              }
              handleChange={(e, { value, name }) => {}}
              showButton={true}
              handleAdd={(value) =>
                handleAddToPO({
                  quantity: value,
                  product: {
                    name: lowStock_product_view[product].product_name,
                    sku: lowStock_product_view[product].product_sku,
                    _id: product,
                  },
                  warehouse: {
                    // _id: item.warehouse,
                    // name: warehouse.warehouse_name,
                  },
                  businessclient: {
                    _id: lowStock_product_view[product].businessclient,
                    name: lowStock_product_view[product].businessclient_name,
                  },
                })
              }
            />
          </div>
        </TableCell>
      </TableRow>
    );
    view_total_product.push(newRow);
  }

  return (
    <div>
      <Message
        // warning
        style={{
          minWidth: "40%",
          maxWidth: "1000px",
          marginBottom: "10px",
          marginRight: "10px",
        }}
      >
        <div className="flex--between">
          <div>
            <MessageHeader>Product View</MessageHeader>
          </div>

          {/* <Button
            basic
            size="tiny"
            style={{ maxHeight: "30px" }}
            onClick={() =>
              setLowStockWarehouseView(JSON.parse(localStorage.getItem("lowstock")))
            }
          >
            View All
          </Button> */}
        </div>
        <Table
          basic
          // style={{maxWidth:'800px'}}
        >
          {/* <TableHeader>
            <TableRow>
              <TableHeaderCell>Product</TableHeaderCell>
              <TableHeaderCell>Warehouse</TableHeaderCell>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
            </TableRow>
          </TableHeader> */}
          <TableBody>{view_total_product}</TableBody>
        </Table>
      </Message>
    </div>
  );
};

export default Lowstock_per_product;
