import React, { useState } from "react";
import {
  Table,
  Button,
  Checkbox,
  Icon,
  Label,
  Header,
  Loader,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import CreateUpdateTime from "../../CreateUpdateTime";
// import SortArrows from "./SortArrows";
import ItemsPerPage from "../../ItemsPerPage";
import { Dropdown } from "rsuite";
import ExportButton from "../../ExportButton";
import PageIcon from "@rsuite/icons/Page";
import Exportids from "./Exportids";
import CanView from "../../../role-based-access/CanView";
// import { isMobile } from "react-device-detect";
import OrdersListMenu from "./OrdersListMenu";

export const statusColor = {
  Error: "red",
  "No goods": "orange",
  "Order Ready for picking": "teal",
  "Product Picking": "blue",
  Packed: "green",
  Draft: "yellow",
  "Not shipped": "olive",
  Canceled: "red",
  "In progress": "pink",
};

function OrdersTable({
  orders,
  itemsPerPage,
  currentPage,
  checkedItems,
  headerIsChecked,
  handleMultipleCheck,
  handleSingleCheck,
  checkColumn,
  actionColumn,
  isLoading,
  orderLink = "/orders/",
  action = "edit",
  filters = { status: "Product picking" },
  total,
  setItemsPerPage,

  openChangeStatusModal,
  openChangeStatusModalFromInput,
  openShipFromWarehouseModal,
  openAssignUserModal,
  toggleAssignCourierModal,
  manualAssing,
}) {
  const [openInputExp, setExportInputOpen] = useState(false);
  if (isLoading) return <div>Loading table...</div>;

  const exportmenu = (
    <Dropdown title={`Export ${total}`} icon={<PageIcon />}>
      <Dropdown.Item>
        <ExportButton
          total={total}
          filters={filters}
          fileName={`orders_${filters.status}`}
          url="/orders/export/perline"
          text="Per Product line"
        />
      </Dropdown.Item>

      <Dropdown.Item>
        <ExportButton
          total={total}
          filters={filters}
          fileName={`orders_${filters.status}`}
          url="/orders/export"
          text="Per Order line"
        />
      </Dropdown.Item>
      <Dropdown.Item onSelect={(key, e) => setExportInputOpen(true)}>
        <Exportids
          openModal={openInputExp}
          setExportInputOpen={setExportInputOpen}
        />
      </Dropdown.Item>
    </Dropdown>
  );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      {checkColumn && (
        <Table.HeaderCell>
          <Checkbox
            style={{ marginTop: "1em" }}
            onChange={handleMultipleCheck}
            checked={headerIsChecked}
          />
        </Table.HeaderCell>
      )}
      <Table.HeaderCell>Order</Table.HeaderCell>
      <Table.HeaderCell width={2}>Status</Table.HeaderCell>
      <Table.HeaderCell width={3}>Client</Table.HeaderCell>
      <Table.HeaderCell width={2}>
        Dates
        {/* {sortable && <SortArrows 
                        updateFilter={handleUpdateFilter}  
                        sortBy="byDate" />} */}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="left">
        Items
        {/* {sortable && <SortArrows sortBy="byProduct" />} */}
      </Table.HeaderCell>
      <Table.HeaderCell  width={2}>Country</Table.HeaderCell>
      {/* <Table.HeaderCell>AWB</Table.HeaderCell> */}
      <Table.HeaderCell  width={1}>Courier</Table.HeaderCell>
      <Table.HeaderCell>Assigned User</Table.HeaderCell>
      {actionColumn && <Table.HeaderCell>Action</Table.HeaderCell>}
    </Table.Row>
  );

  const tableRows = orders.map(
    (
      {
        _id,
        clientOrderNr,
        status,
        createdAt,
        updatedAt,
        shipping,
        processing,
        errMsgs,
        items,
        businessclient,
        eshop,
        internalNumericId,
      },
      index
    ) => {
      let errors = [];
      let products = [];
      if (errMsgs && errMsgs.length > 0) {
        for (let i = 0; i < errMsgs.length; i++) {
          if (i > 1) break;
          errors.push(
            <div>{errMsgs[i] ? errMsgs[i].slice(0, 20) : "error null."}</div>
          );
        }
      }

      if (items && items.length > 0) {
        // eslint-disable-next-line array-callback-return
        products = items.map((item) => {
          if (item) {
            let name = item.name ? item.name : "Na";
            const length = name.length;

            if (length > 15) name = `${name.substring(0, 15)}...`;
            if (item.product)
              return (
                <Link target="_blank" to={`/products/${item.product._id}`}>
                  <div style={{ whiteSpace: "pre" }}>
                    {item.low_stock && <Icon name="attention" color="red" />}
                    {!item.stockAvailable && (
                      <Icon name="attention" color="yellow" />
                    )}
                    {item.stockAvailable && (
                      <Icon name="checkmark" color="olive" />
                    )}
                    {name} -{item.quantity}
                  </div>
                </Link>
              );
          }
        });
      }
      return (
        <Table.Row key={_id} textAlign="left">
          <Table.Cell>
            {typeof itemsPerPage === "number" &&
              itemsPerPage * (currentPage - 1) + index + 1}
            {typeof itemsPerPage != "number" && index + 1}
          </Table.Cell>

          {checkColumn && (
            <Table.Cell>
              <Checkbox
                _id={_id}
                // style={{ marginTop: "0.1em" }}
                checked={checkedItems.includes(_id)}
                onChange={(e, data) => handleSingleCheck(data._id)}
              />
            </Table.Cell>
          )}

          <Table.Cell>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {processing.islowStock && <Label color='red'>Low Stock</Label>}

              {/* <span>{clientOrderNr}</span> */}
              <Link to={orderLink + _id} target="_blank">
                {clientOrderNr}
              </Link>
              <span className="smallinfo">
                {internalNumericId ? internalNumericId : ""}
              </span>
              {eshop && eshop.eshopUrl && (
                <span className="smallinfo">{eshop.eshopUrl}</span>
              )}
            </div>
          </Table.Cell>

          <Table.Cell>
            <Label color={statusColor[status]} horizontal>
              {status}
            </Label>
            {shipping.deliveryDate && (
              <div className="smallinfo">
                Deliver:{" "}
                <Moment
                  format="DD-MM-YY HH:mm"
                  style={{ whiteSpace: "pre" }}
                  utc
                >
                  {shipping.deliveryDate}
                </Moment>
              </div>
            )}
            {processing.codTransfferId && (
              <div className="smallinfo">
                COD Trasffer |{" "}
                <Link
                  target="_blank"
                  to={`/transfers/${processing.codTransfferId}`}
                >
                  view
                </Link>
              </div>
            )}
            {processing && processing.isOpenUser && (
              <div className="smallinfo">open by: {processing.isOpenUser}</div>
            )}
            <br />
            <div className="smallinfo" style={{ maxWidth: "130px" }}>
              {errors}
            </div>
          </Table.Cell>
          <Table.Cell>
            <Link
              target="_blank"
              to={`/businessclients/${
                businessclient ? businessclient._id : ""
              }`}
            >
              {businessclient && businessclient.name}
            </Link>
          </Table.Cell>
          <Table.Cell>
            <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
          </Table.Cell>

          <Table.Cell textAlign="left">{products}</Table.Cell>
          <Table.Cell>
            <div className="flex--column">
              {shipping.shipTo.country}
              <span className="smallinfo">
                {shipping.shipFrom && shipping.shipFrom.name
                  ? shipping.shipFrom.name
                  : ""}
              </span>
            </div>
          </Table.Cell>
          {/* <Table.Cell>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={shipping.trackingUrl}
            >
              {shipping.trackingId}
            </a>
          </Table.Cell> */}

          <Table.Cell>
            {shipping.courier && shipping.courier._id && (
              <Link to={`/couriers/${shipping.courier._id}`}>
                {shipping.courier ? shipping.courier.name : ""}
              </Link>
            )}
            <br></br>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={shipping.trackingUrl}
            >
              {shipping.trackingId}
            </a>{" "}
          </Table.Cell>

          <Table.Cell>
            {processing && processing.user_id && processing.user_id.username}
          </Table.Cell>

          {actionColumn && (
            <Table.Cell>
              {action === "edit" && (
                <Link
                  to={orderLink + _id}
                  // target="_blank"
                >
                  <Button basic color="blue" size="tiny">
                    <Icon name="edit outline" />
                    Edit
                  </Button>
                </Link>
              )}
              {action === "pack" && (
                <Link to={`/packer/orders/${_id}`}>
                  <Button color="orange" size="tiny" basic>
                    Pack
                  </Button>
                </Link>
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    }
  );

  if (!orders.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
        No Orders Found
      </Header>
    );
  }
  return (
    <div
    // className="table-wrapper--horizontal-scroll"
    >
      <div className="flex--between" style={{ marginTop: "20px" }}>
        <CanView path="/orders" action="actions">
          <OrdersListMenu
            checkedItems={checkedItems}
            orders={orders}
            openChangeStatusModal={openChangeStatusModal}
            openChangeStatusModalFromInput={openChangeStatusModalFromInput}
            openShipFromWarehouseModal={openShipFromWarehouseModal}
            openAssignUserModal={openAssignUserModal}
            toggleAssignCourierModal={toggleAssignCourierModal}
            manualAssing={manualAssing}
            // getWarehouses={getWarehouses}
          />
        </CanView>
        <div className="flex--left">
          <div style={{ marginRight: "50px" }}>
            <CanView path="/orders" action="export">
              {exportmenu}
            </CanView>
          </div>

          {
            <ItemsPerPage
              total={total}
              style={{ height: "1em" }}
              value={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          }
        </div>
      </div>

      <Table
        singleLine={false}
        fixed={false}
        striped
        // textAlign="left"
      >
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{!isLoading && orders.length && tableRows}</Table.Body>
      </Table>

      {isLoading && (
        <div style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Loader active inline="centered" />
        </div>
      )}
    </div>
  );
}

export default OrdersTable;
