import React, { useState, useEffect } from "react";
import { Table, Header, Label, Icon, Button } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import { ExcelRenderer } from "react-excel-renderer";
import ExportExcel from "../ExportExcel";

import ServiceTypes from "../Filters/ServiceTypes";

import BusinessclientFilter from "../Filters/BusinessclientFilter";
import CourierFilter from "../Filters/CourierFilter";
import CountryFilter from "../Filters/CountryFilter";
import { dataSet, colNames } from "./uploadtemplate";
import { importServiceQuotation } from "../../actions/servicequotation";
import { Alert } from "rsuite";
import "../Dropzone/dropzone.scss";

const initState = {
  dragContent: "Drag 'n' drop the file or click here",
  fileName: "",
  formatError: false,
  data: [],
  errors: {},
  businessclient: null,
  supplier: null,
  serviceType: null,
  courier: null,
  country: null,
};

function ImportQuotations(props) {
  const [state, setState] = useState(initState);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyNames, setKeynames] = useState([]);

  useEffect(() => {
    const columns = colNames.map((col) => col.value);
    setKeynames(columns);
  }, []);
  const fileHandler = (files) => {
    let fileObj = files[0];
    if (!fileObj.name.includes(".xlsx")) {
      setState({
        formatError: true,
        fileName: "",
        data: [],
        dragContent: "Drag 'n' drop the file or click here",
      });
      return;
    }

    const requiredKeys = ["category", "currency", "amount"];

    function mapRows(arr) {
      let data = [];
      let errors = {};
      arr.shift();
      arr.forEach((row, i) => {
        let product = {};
        let productErrs = [];
        for (let j = 0; j < row.length; j++) {
          product[keyNames[j]] = row[j];
          if (requiredKeys.includes(keyNames[j]) && row[j] === undefined) {
            productErrs.push(keyNames[j]);
          }
        }
        data.push(product);
        if (productErrs.length) errors[i] = productErrs;
      });
      return [data, errors];
    }

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        const [data, errors] = mapRows(resp.rows);

        setState({
          ...state,
          fileName: fileObj.name,
          formatError: false,
          data,
          errors,
        });
      }
    });
  };

  const handleSaveQuotations = () => {
    //  console.log('uploading data:',state.data)

    const { serviceType, supplier, businessclient, courier, country } = state;
    Alert.info("Working...");
    importServiceQuotation({
      list: state.data,
      serviceType,
      businessclient,
      supplier,
      courier,
      country,
    })
      .then((resp) => Alert.success("Done."))
      .catch((err) => Alert.error(err.message));
  };

  const tblHeaderes = keyNames.map((key) => (
    <Table.HeaderCell>{key}</Table.HeaderCell>
  ));

  const tableHeadRow = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Valid</Table.HeaderCell>
      {tblHeaderes}
    </Table.Row>
  );

  const tableRows = state.data.map((p, i) => (
    <Table.Row key={i} error={!!state.errors[i]}>
      <Table.Cell>{i + 1}</Table.Cell>
      <Table.Cell collapsing>
        {state.errors[i] ? (
          <Icon name="remove circle" color="red" size="large" />
        ) : (
          <Icon name="check circle" color="green" size="large" />
        )}
        {/* <Label circular color="green">
            <Icon name="check" />
          </Label> */}
      </Table.Cell>

      {p.category === undefined ? (
        <Table.Cell negative className="bold--text">
          ! required
        </Table.Cell>
      ) : (
        <Table.Cell>{p.category}</Table.Cell>
      )}

      {/* {p.sku=== undefined ? (
          <Table.Cell negative className="bold--text">
            ! required
          </Table.Cell>
        ) : (
          <Table.Cell>{p.sku}</Table.Cell>
        )} */}

      {p.currency === undefined ? (
        <Table.Cell negative className="bold--text">
          ! required
        </Table.Cell>
      ) : (
        <Table.Cell>{p.currency}</Table.Cell>
      )}

      {p.amount === undefined ? (
        <Table.Cell negative className="bold--text">
          ! required
        </Table.Cell>
      ) : (
        <Table.Cell>{p.amount}</Table.Cell>
      )}
    </Table.Row>
  ));

  return (
    <div className="products-from-excel">
      <div className="flex--left">
        <div style={{ marginRight: "1em" }}>
          <ExportExcel
            filename="quoatation-template"
            excelSheetName="upload-quoatation-template"
            dataSet={dataSet}
            colNames={colNames}
            button={
              <Button.Group basic>
                <Button>
                  <Icon name="download" />
                  Download Exemple
                </Button>
              </Button.Group>
            }
          />
        </div>

        <BusinessclientFilter
          name="businessclient"
          useFilter={false}
          label="Business client"
          value={state.businessclient}
          handleChange={(value) =>
            setState({ ...state, businessclient: value })
          }
        />
        <BusinessclientFilter
          useFilter={false}
          placeholder="product supplier"
          value={state.supplier}
          handleChange={(value) => setState({ ...state, supplier: value })}
        />
        <ServiceTypes
          handleChange={(value) => setState({ ...state, serviceType: value })}
          value={state.serviceType}
          multiple={false}
          loadAll={true}
        ></ServiceTypes>

        <CourierFilter
          useFilter={false}
          handleChange={(value) => setState({ ...state, courier: value })}
        ></CourierFilter>
        <CountryFilter
          handleChange={(value) => setState({ ...state, country: value })}
          useFilter={false}
        ></CountryFilter>
      </div>

      <section className="upload-file">
        <Header>1. Upload a file with quotations (.xlsx format)</Header>
        {/* <form className="upload-file__form">
            <label className="upload-file__input" for="file-upload">
              Upload file
              <input
                id="file-upload"
                type="file"
                placeholder="Add Excel File"
                onChange={e => this.fileHandler(e.target)}
              />
            </label>
          </form> */}

        {!state.fileName && (
          <Dropzone
            onDrop={(acceptedFiles) => fileHandler(acceptedFiles)}
            onDragEnter={() =>
              setState({ ...state, dragContent: "Drop here ..." })
            }
          >
            {({ getRootProps, getInputProps }) => (
              <section className="upload-file__drop-container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>{state.dragContent}</p>
                </div>
              </section>
            )}
          </Dropzone>
        )}

        {state.fileName && (
          <div className="upload-file__file-name">
            <Label image>
              <Icon name="file" />
              {state.fileName}
              <Icon name="delete" onClick={() => setState(initState)} />
            </Label>
          </div>
        )}
        {state.formatError && (
          <p className="upload-file__error">
            * Must be an Excel file ( .xlsx )
          </p>
        )}
      </section>

      {/* View Table */}
      <section className="view-table">
        {state.data && state.data.length > 0 && (
          <div className="table-wrapper--horizontal-scroll">
            <Table unstackable celled>
              <Table.Header>{tableHeadRow}</Table.Header>
              <Table.Body>{tableRows}</Table.Body>
            </Table>
          </div>
        )}
      </section>

      {/* Feedback */}
      {state.data &&
        state.data.length > 0 &&
        !success &&
        (!Object.keys(state.errors).length ? (
          <section className="feedback-section feedback-section_success">
            <p className="feedback-section__success">
              <Icon name="check" color="green" />
              There are no errors. The file seems to pass the requirements.
              <br /> You can save the products now.
            </p>
          </section>
        ) : (
          <section className="feedback-section feedback-section_errors">
            <p className="feedback-section__errors">
              <Icon name="warning sign" color="yellow" />
              There are some errors.
              <br /> The quotations with missing required information will not
              be saved.
              <br /> If you want to add them too, please updatemissing
              infomartion and reupload the file.
            </p>
          </section>
        ))}

      {/* Save Products */}
      <section className="save-products">
        <Header>2. Save uploaded quotations</Header>
        {success && (
          <Button color="green" size="huge">
            Quotations Saved
          </Button>
        )}
        {!success && (
          <Button
            color="teal"
            basic
            disabled={!state.serviceType || state.data.length === 0}
            loading={loading}
            size="huge"
            onClick={handleSaveQuotations}
          >
            Save Quotations
          </Button>
        )}
      </section>

      {/* Server Response */}

      {/* {this.props.unsuccessful &&
          this.props.unsuccessful.length > 0 &&
          this.props.status === "fail" &&
          state.fileName && (
            <section className="feedback-section feedback-section_server-response">
              <div className="table-wrapper--horizontal-scroll">
                <Table unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Error</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.unsuccessful &&
                      this.props.unsuccessful.map((p, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            {p.name} - {p["codes.sku"]}
                          </Table.Cell>
                          <Table.Cell>{p.error}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </section>
          )} */}

      {success && state.fileName && (
        <section className="feedback-section feedback-section_success feedback-section_server-response">
          <p className="feedback-section__success">
            <Icon name="check" color="green" />
            All quotations successfully saved
          </p>
        </section>
      )}
    </div>
  );
}

export default ImportQuotations;
