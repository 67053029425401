import React, { useState, useEffect } from "react";
import {
  Form,
  Divider,
  Header,
  Button,
  Pagination as Pagination2,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import Filters from "./Filters";
import {
  getOrders,
  manualAssing,
  getOrdersSearch,
  changeShipFromSelection,
  getOrdersListIds,
} from "../../../actions/orders";
// import Deliveryratecards from "./Deliveryratecards";
import CanViewPage from "../../../role-based-access/CanViewPage.jsx";
import CanView from "../../../role-based-access/CanView.jsx";
import TableActions from "./TableActions";
import Tabs from "./Tabs";
import OrdersTable from "./OrdersTable";
import ChangeStatusModal from "./ChangeStatusModal";
import AssignCourierModal from "./AssigncourierModal";
import ChangeStatusFromInputModal from "./ChangeStatusFromInputModal";
import AssignUserModal from "./AssignUserModal";
import "../Order.scss";
import { isMobile } from "react-device-detect";
import { useUpdateContext } from "../../../context/UpdateContext";
// import { Alert } from "rsuite";
import moment from "moment";

const initState = {
  checkedItems: [],
  headerIsChecked: false,
  isChangeStatusModalOpen: false,
  inputModal: false,
  warehouseModal: false,
  isAssignUserModalOpen: false,
  isCourierModalOpen: false,
  isDnDdisplayed: true,
  requestSent: false,
  // search: "",
};

const OrdersListPage = (props) => {
  const {
    businessclient,
    warehouse,
    shipTo,
    dateFrom,
    handleDateFromChange,
    dateTo,
    product,
    country,
    courier,
    user,
  } = useUpdateContext();

  const [state, setState] = useState(initState);
  const [orders, setOrders] = useState({
    list: [],
  });

  const [totals, setTotals] = useState([["New", "loading"]]);
  const [searchTerm, setSearchTerm] = useState();
  const [clientOrderIds, setClientOrderIds] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [totalsLoading, setLoadingTotals] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [status, setStatus] = useState("Order Ready for picking");
  const [reload, setReload] = useState(false);
  const [skus, setSkus] = useState();
  const [use_createdAt, setFilterDate] = useState(true);

  const filters = {
    businessclient,
    warehouse,
    shipTo,
    dateFrom,
    dateTo,
    product,
    country,
    courier,
    status,
    clientOrderIds,
    searchTerm,
    skus,
    use_createdAt,
  };
  const handleClearCheckedItems = () => {
    setState({ ...state, checkedItems: [], headerIsChecked: false });
  };

  const handleSetStatus = (status) => {
    if (status === "All") {
      const date = moment().subtract(30, "days");
      handleDateFromChange(date);
    }
    setStatus(status);
    setCurrentPage(1);
  };
  const handleSearchIds = (ids) => {
    setClientOrderIds(ids);
    if (ids === "") setStatus("New");
    setIsloading(true);
    if (ids)
      getOrdersListIds(ids).then((resp) => {
        setIsloading(false);
        setOrders(resp);
        setTotals(resp.totals);
      });
    else setReload(!reload);
  };

  const handleSearchTerm = (searchTerm) => {
    setIsloading(true);
    console.log("searchTerm:".searchTerm);
    setSearchTerm(searchTerm);
    if (searchTerm === "") setStatus("New");
    if (searchTerm)
      getOrdersSearch(searchTerm).then((resp) => {
        setIsloading(false);
        setOrders(resp);
        setTotals(resp.totals);
      });
    else setReload(!reload);
  };

  useEffect(() => {
    setIsloading(true);
    getOrders({
      businessclient,
      warehouse,
      shipTo,
      dateFrom,
      dateTo,
      product,
      country,
      courier,
      status,
      assigned: user,
      itemsPerPage,
      currentPage,
      skus,
      use_createdAt,
    }).then((resp) => {
      setOrders(resp);
      setTotals(resp.totals);
      setIsloading(false);
      handleClearCheckedItems();
    });
  }, [status, itemsPerPage, currentPage, reload]);

  const handleSingleCheck = (id) => {
    setState((prevState) => {
      if (state.checkedItems.includes(id)) {
        const i = state.checkedItems.indexOf(id);
        const len = state.checkedItems.length;
        return {
          checkedItems: [
            ...state.checkedItems.slice(0, i),
            ...state.checkedItems.slice(i + 1, len),
          ],
        };
      } else {
        return {
          checkedItems: [...state.checkedItems, id],
        };
      }
    });
  };

  const handleMultipleCheck = () => {
    if (!state.headerIsChecked) {
      setState({
        ...state,
        checkedItems: orders.list.map((el) => el._id),
        headerIsChecked: true,
      });
    } else {
      handleClearCheckedItems();
    }
  };

  const toggleAssignUserModal = () => {
    setState({ ...state, isAssignUserModalOpen: !state.isAssignUserModalOpen });
  };
  const toggleAssignCourierModal = () => {
    setState({ ...state, isCourierModalOpen: !state.isCourierModalOpen });
  };

  const closeStatusModal = () => {
    setState({ ...state, isChangeStatusModalOpen: false });
  };
  const toggleChangeStatusFromInputModal = () => {
    setState({ ...state, inputModal: !state.inputModal });
  };

  const toggleChangeShipFromWarehouseModal = () => {
    setState({ ...state, warehouseModal: !state.warehouseModal });
  };

  const handleOpenModal = () => {
    setState({ ...state, isChangeStatusModalOpen: true });
  };

  const {
    isChangeStatusModalOpen,
    inputModal,
    isAssignUserModalOpen,
    headerIsChecked,
    checkedItems,
    isDnDdisplayed,
  } = state;

  // const checkedItemsCONr = orders
  //   .filter(({ _id }) => checkedItems.includes(_id))
  //   .map(({ clientOrderNr }) => clientOrderNr);
  let total = 0;
  const nr = totals ? totals.find((tab) => tab[0] === status) : 0;
  if (nr && nr.length > 0) total = nr[1];

  return (
    <CanViewPage path="/orders">
      {/* <div style={{ margin: "15px" }}>
        Today: <Moment format="DD-MM-yy" />
      </div> */}
      
      <div style={{ maxWidth: "1200px", margin: "20px" }}>

      

        <div className="flex_spaceBetween" style={{ marginBottom: "0.5em" }}>
          <Header as="h2">Orders</Header>
          <div>
            {/* <Picking></Picking> */}
            <CanView path="/orders" action="start picking">
              <Link to="/picker/orders">
                {!isMobile && (
                  <Button
                    style={{ width: "200px", maxWidth: "360px" }}
                    color="blue"
                  >
                    Start Picking
                  </Button>
                )}
              </Link>
            </CanView>
            <CanView path="/orders" action="create new">
              <Link to="/orders/create/new">
                <Button style={{ width: "200px", maxWidth: "360px" }} basic>
                  Create New Order
                </Button>
              </Link>
            </CanView>
          </div>
        </div>

        <Divider />

        {/* <Deliveryratecards
          isMobile={isMobile}
          dateFrom={dateFrom}
          dateTo={dateTo}
          courier={courier}
          product={product}
          businessclient={businessclient}
          warehouse={warehouse}
        ></Deliveryratecards> */}

        <Tabs
          totalsLoading={totalsLoading}
          setStatus={handleSetStatus}
          totals={totals}
          selectedStatus={status}
        />

        <div>
          <ChangeStatusModal
            isOpen={isChangeStatusModalOpen}
            // handleSearchOrders={handleSearchOrders}
            checkedItems={checkedItems}
            isDnDdisplayed={isDnDdisplayed}
            closeStatusModal={closeStatusModal}
            handleClearCheckedItems={handleClearCheckedItems}
          />

          <ChangeStatusFromInputModal
            isOpen={inputModal}
            isDnDdisplayed={isDnDdisplayed}
            handleToggleModal={toggleChangeStatusFromInputModal}
            filters={filters}
          />

          <CanViewPage path="/orders" action="actions">
            <AssignUserModal
              isModalOpen={isAssignUserModalOpen}
              handleCloseModal={toggleAssignUserModal}
              checkedOrders={checkedItems}
              manualAssing={manualAssing}
            />
          </CanViewPage>
          <CanViewPage path="/orders" action="actions">
            <AssignCourierModal
              isModalOpen={state.isCourierModalOpen}
              handleCloseModal={toggleAssignCourierModal}
              checkedOrders={checkedItems}
            />
          </CanViewPage>

          {/* WAREHOUSE MODAL */}
          <Modal
            open={state.warehouseModal}
            showCloseIcon={true}
            closeOnEsc={true}
            onClose={toggleChangeShipFromWarehouseModal}
            center
            classNames={{
              modal: "",
            }}
          >
            <Form>
              <Header>ShipFrom warehouse </Header>
              <Divider></Divider>

              <Form.Group>
                <WarehouseFilter
                  useFilter={false}
                  multiple={false}
                  warehouse={state.warehouse}
                  handleChange={(value) =>
                    setState({ ...state, warehouse: value })
                  }
                ></WarehouseFilter>

                <Form.Button
                  onClick={() => {
                    setIsloading(true);
                    toggleChangeShipFromWarehouseModal();
                    changeShipFromSelection({
                      arrayOfIds: checkedItems,
                      warehouse: state.warehouse,
                    }).then((resp) => {
                      setReload(!reload);
                    });
                  }}
                >
                  Ok
                </Form.Button>
              </Form.Group>

              {/* <EditableDropdown
                            placeholder="Select Destination"
                            value={shipTo}
                            options={createOptions(warehouses)}
                            handleChange={handleDestChange}
                          />
                        */}
            </Form>
          </Modal>

          <TableActions
            skus={skus}
            setSkus={setSkus}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearchIds={handleSearchIds}
            handleSearchTerm={handleSearchTerm}
            clientOrderIds={clientOrderIds}
            setClientOrderIds={setClientOrderIds}
          />

          <Filters
            isLoading={isLoading}
            setSearchButton={setReload}
            searchButton={reload}
            use_createdAt={use_createdAt}
            setFilterDate={setFilterDate}
          />

          <OrdersTable
            filters={filters}
            orders={orders.list}
            total={total}
            checkedItems={checkedItems}
            headerIsChecked={headerIsChecked}
            handleSingleCheck={handleSingleCheck}
            handleMultipleCheck={handleMultipleCheck}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            checkColumn={true}
            actionColumn={true}
            isLoading={isLoading}
            openChangeStatusModal={handleOpenModal}
            openChangeStatusModalFromInput={toggleChangeStatusFromInputModal}
            openShipFromWarehouseModal={toggleChangeShipFromWarehouseModal}
            openAssignUserModal={toggleAssignUserModal}
            toggleAssignCourierModal={toggleAssignCourierModal}
            manualAssing={manualAssing}
          />

          {total > 0 && itemsPerPage && !isLoading && (
            <Pagination2
              style={{ marginTop: "1em" }}
              activePage={currentPage}
              totalPages={Math.ceil(total / itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
            />
          )}
        </div>

        {/* <OrdersList
      // handleUpdateFilter={handleUpdateFilter}
      /> */}
      </div>
    </CanViewPage>
  );
};

export default OrdersListPage;
