import React, { useState, useEffect } from "react";
import { Drawer, Button, Divider } from "rsuite";
import { Link } from "react-router-dom";

import { useUpdateContext } from "../../context/UpdateContext";
import { getLowStockProducts } from "../../actions/reports";

const Lowstock = (props) => {
  const { setLowStockWarehouseView, lowStock_warehouse_view } =
    useUpdateContext();

  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    if (
      !localStorage.getItem("lowstock") ||
      localStorage.getItem("lowstock") === "undefined"
    )
      localStorage.setItem("lowstock", []);

      // console.log("is today: ", isToday(localStorage.getItem("lowstock_date")));
    if (
      !isToday(localStorage.getItem("lowstock_date")) ||
      !localStorage.getItem("lowstock")
    ) {
      getLowStockProducts().then((resp) => {
        setLowStockWarehouseView(resp);
        localStorage.setItem("lowstock", JSON.stringify(resp));
        localStorage.setItem("lowstock_date", new Date());
      });
    } else
      setLowStockWarehouseView(JSON.parse(localStorage.getItem("lowstock")));
  }, []);

  let total = 0;

  const data =
    !localStorage.getItem("lowstock") ||
    localStorage.getItem("lowstock") === "undefined"
      ? lowStock_warehouse_view
      : JSON.parse(localStorage.getItem("lowstock"));

  const view_data = (
    <table style={{ borderSpacing: "2px" }}>
      {data.map((item) => {
        // console.log("item:", item);
        total = total + item.products.length;
        return (
          <tr style={{ height: "10px" }}>
            <td>{item.warehouse.name} </td>
            <td>{item.businessclient.name} </td>
            <td>{item.products.length} products</td>
            <td>
              <Link to="/reports/lowstocks">
                <Button
                  basic
                  size="xs"
                  onClick={() => {
                    setLowStockWarehouseView([item]);
                    setShowCard(false);
                  }}
                >
                  view
                </Button>
              </Link>
            </td>
          </tr>
        );
      })}
    </table>
  );

  return (
    <div style={{ marginRight: "20px" }}>
      <Link to="/reports/lowstocks">
        <Button onClick={() => setShowCard(true)} color="orange">
          Low stocks -{total} products
        </Button>
      </Link>
      {/* <Drawer show={showCard} onHide={() => setShowCard(false)} size="sm">
        <Drawer.Header>
          <Drawer.Title>Low Stocks </Drawer.Title>
        </Drawer.Header>

        <Divider />
        <Drawer.Body>
          {view_data}
          <Divider />
          <Link to="/reports/lowstocks">
            <Button
              appearance="primary"
              onClick={() => {
                setLowStockWarehouseView(
                  JSON.parse(localStorage.getItem("lowstock"))
                );
                setShowCard(false);
              }}
            >
              {" "}
              Open report{" "}
            </Button>
          </Link>
        </Drawer.Body>

        <Drawer.Footer>
          <div className="mb--1">
            <Button onClick={() => setShowCard(false)}>Close</Button>
          </div>
        </Drawer.Footer>
      </Drawer>
       */}
    </div>
  );
};

export default Lowstock;

function isToday(givenDate) {
  // Get today's date with only the year, month, and day
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Ensure the givenDate is also a Date object
  const date = new Date(givenDate);
  date.setHours(0, 0, 0, 0);

  // Compare the two dates
  return today.getTime() === date.getTime();
}
