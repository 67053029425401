import React from "react";
import { Table, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import EditableText from "../../EditableText";

import { useUpdateContext } from "../../../context/UpdateContext";

function Turnovertable({ stocks, isLoading, currentPage, itemsPerPage }) {
  const { handleAddToPO } = useUpdateContext();

  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      {/* <Table.HeaderCell>
        <Checkbox checked={isHeaderChecked} onChange={handleMultipleCheck} />
      </Table.HeaderCell> */}
      <Table.HeaderCell width={1}>#</Table.HeaderCell>
      <Table.HeaderCell width={2}>SKU</Table.HeaderCell>
      <Table.HeaderCell width={3}>NAME</Table.HeaderCell>
      <Table.HeaderCell width={2}>Warehouse</Table.HeaderCell>
      <Table.HeaderCell width={1}>Balance</Table.HeaderCell>

      <Table.HeaderCell width={1}>TurnOver</Table.HeaderCell>
      <Table.HeaderCell width={1}>Days remain</Table.HeaderCell>
      <Table.HeaderCell width={1}>In tranzit (POs)</Table.HeaderCell>
      <Table.HeaderCell warning width={1}>Supplier Balance</Table.HeaderCell>
      <Table.HeaderCell width={2}>Create PO</Table.HeaderCell>
    </Table.Row>
  );

  let tableBody = [];
  let i = 0;

  for (const key in stocks) {
    let item = stocks[key];

    const str = item.turnover ? item.turnover.toString() : "";
    const len = str !== "" ? str.length : 0;
    const first = str.substring(0, 1);
    const value = (first * 1+1 ) * Math.pow(10, len - 1);
    
    tableBody.push(
      <Table.Row key={i}>
        {/* <Table.Cell>
      <Checkbox
        checked={checkedItems.includes(_id)}
        onChange={() => handleSingleCheck(_id)}
      />
    </Table.Cell> */}
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>
          <Link to={`/products/${item.product}`}>{item.product_sku} </Link>{" "}
        </Table.Cell>
        <Table.Cell>{item.product_name}</Table.Cell>
        <Table.Cell>{item.warehouse_name}</Table.Cell>
        <Table.Cell>{item.stock}</Table.Cell>
        <Table.Cell>{item.turnover}</Table.Cell>
        <Table.Cell>{item.days_of_inventory}</Table.Cell>
        <Table.Cell>
          {item.in_tranzit &&
            item.in_tranzit.map((item) => (
              <Link to={`/pos/${item.po_ids[0]}`}>
                {item.status}-{item.total}
              </Link>
            ))}
        </Table.Cell>
        <Table.Cell warning>
          {item.supplier_available ? item.supplier_available : 0}
        </Table.Cell>
        <Table.Cell textAlign="left">
          <div>
            <EditableText
              // maxWidth={"80px"}
              style={{ minWidth: "80px" }}
              fluid={false}
              name="quantity_shipped"
              value={item.supplier_available &&( Math.round(item.supplier_available * 0.4)>value)? value : 0}
              // value={item.supplier_available &&Math.round(item.supplier_available * 0.4)<=item.turnover? Math.round(item.supplier_available * 0.3) : 0}
              handleChange={(e, { value, name }) => {}}
              showButton={true}
              handleAdd={(value) =>
                handleAddToPO({
                  quantity: value,
                  product: {
                    name: item.product_name,
                    sku: item.product_sku,
                    _id: item.product,
                  },
                  warehouse: {
                    _id: item.warehouse,
                    name: item.warehouse_name,
                  },
                  businessclient: {
                    _id: item.businessclient,
                    name: item.businessclient_name,
                  },
                })
              }
            />
          </div>
        </Table.Cell>{" "}
      </Table.Row>
    );
    i++;
  }

  return (
    <Table stackable={true} fixed={true} basic="very">
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default Turnovertable;
