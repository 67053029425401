import {
  apiCall
} from "../services/api";

export const createPo = ({
  query
}) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/create/newpo`;
    return apiCall("post", url, query)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));
  })

};

export const getPo = id => {
  const url = `${process.env.REACT_APP_API}/pos/${id}`;
  return new Promise((resolve, reject) => {
    apiCall("get", url)
      .then(resp => resolve(resp))
      .catch(err => {
        reject(err.message)
      });
  })

};

export const updatePo = query => {

  return new Promise((resolve, reject) => {
    const {
      id
    } = query;
    const url = `${process.env.REACT_APP_API}/pos/${id}`;
    apiCall("put", url, query)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err.message)
      });
  })

};

export const updateGR = ({
  po_id,
  item_id,
  quantity
}) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${po_id}/goodsreceived/${item_id}`;

    apiCall("post", url, {
        quantity
      })
      .then(po => resolve((po)))
      .catch(err => {
        reject(err.message)
      });
  })

};

export const deletePoItem = ({
  po_id,
  item_id
}) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${po_id}/goodsreceived/${item_id}`;
    apiCall("delete", url)
      .then(resp => resolve(resp))
      .catch(err => {
        reject(err.message)
      });
  })


};

export const addPoItem = ({
  id,
  item
}) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${id}/goodsreceived`;
    apiCall("post", url, {
        item
      })
      .then(resp => resolve(resp))
      .catch(err => reject(err.message))
  })
};


export const getPos = filters => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos`;
    return apiCall("post", url, filters)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));
  })

};


export const deletePo = ({
  id
}) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${id}`;
    apiCall("delete", url)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));
  })
};


export const addPosShipment = ({
  pos,
  history
}) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/create/newshipment`;
    return apiCall("post", url, {
        pos
      })
      .then(resp => {
        history.push(`/shipments/outbound/${resp._id}`);
      })
      .catch(err => reject(err.message));
  })

};


export const blockGoods = ({
  id
}) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${id}/blockgoods`;

    apiCall('post', url)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));
  })


}


export const cancelBlockedGoods = ({
  id
}) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${id}/cacelblockgoods`;

    apiCall('post', url)
      .then(resp => resolve((resp)))
      .catch(err => {
        reject(err.message)
      })
  })

}


export const updatePoShippedQuantity = query => {

  const {
    po_id,
    item_id,
    quantity_shipped
  } = query;

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${po_id}/quantityshipped/${item_id}`;
    apiCall("put", url, {
        item_id,
        quantity_shipped
      })
      .then(resp => resolve((resp)))
      .catch(err => reject(err.message));
  })

};

export const createPoInvoice = ({
  id
}) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/pos/${id}/invoice`

    apiCall('post', url)
      .then(invoice => resolve(invoice))
      .catch(err => reject(err.message))
  })
}


export const createPOfromCard = (list) => {
  const url = `${process.env.REACT_APP_API}/pos/create/fromcard`
  return new Promise((resolve, reject) => {
    apiCall('post', url, list)
      .then(resp =>resolve (resp))
      .catch(err =>reject (err.message))
  })
}