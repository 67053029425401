import React, { useState } from "react";
import { createCo } from "../../../../actions/company";
import { createPo } from "../../../../actions/pos";
import CanViewPage from "../../../../role-based-access/CanViewPage";
import "../../po.scss";
import { Grid, Header, Button, List, Segment } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import BusinessclientFilter from "../../../Filters/BusinessclientFilter";
// import ShiptoaddressFilter from "../../../Filters/ShipToAddress";

import Companyform from "../../../CompanyForm";
// import ShipToForm from "../../../ShipToForm";
import WarehouseFilter from "../../../Filters/WarehouseFilter";
import { Alert } from "rsuite";

const CreatePO = (props) => {
  const [state, setState] = useState({ showCoForm: false, showShipTo: false });
  const [shipFrom, setWarehouse] = useState(null);
  const [shipTo, setWarehouseShipTo] = useState(null);

  // const handleShipTo = (e, { value }) => {
  //   setState({ ...state, shipToAddress: value });
  // };

  const handleCustomerChange = (value) => {
    setState({ ...state, customer: value });
    // props.getCompanies(value);

    // props.getShipToAddress({client:value})
  };

  const handleSupplierChange = (value) => {
    setState({ ...state, supplier: value });
    // props.getClientProductList(value);
  };

  function toggleCompanyModal() {
    setState({ ...state, showCoForm: !state.showCoForm });
  }

  // function toggleAddShipTo() {
  //   setState({ ...state, showShipTo: !state.showShipTo });
  // }

  function handleCreatePo() {
    const { customer, supplier } = state;
    createPo({
      query: {
        shipTo,
        businessclient: customer,
        supplier,
        shipFrom,
      },
    })
      .then((resp) => {
        props.history.push(`/pos/${resp._id}`);
      })
      .catch((error) => Alert.error(error));
  }

  const { customer, showCoForm } = state;

  return (
    <CanViewPage path="/pos/create/new">
      <Segment>
        <div className="flex-row space">
          <h4 style={{ color: "grey", marginBottom: "0.7em" }}>
            Create New Purchase Order
          </h4>
        </div>
        {/* <Divider /> */}
        <Grid columns={3} divided stackable>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">1. Parties</Header>
              <div
              // className="po-form__grid-cell-content po-form__grid-cell-content_parties"
              >
                <label className="parties__row flex--between vertical--bottom">
                  Client:
                </label>
                <BusinessclientFilter
                  useFilter={false}
                  defaultOpen={true}
                  // wizardMode={true}
                  // open={!state.customer}
                  name="customer"
                  value={state.customer}
                  handleChange={handleCustomerChange}
                />
                <label className="parties__row flex--between vertical--bottom">
                  Supplier:
                </label>

                <BusinessclientFilter
                  useFilter={false}
                  open={state.customer}
                  // open={!!state.customer && !state.supplier}
                  name="supplier"
                  disabled={!state.customer}
                  value={state.supplier}
                  handleChange={handleSupplierChange}
                />
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className="flex--between">
                <Header as="h3">2. Shipping </Header>
              </div>

              <div className="po-form__grid-cell-content po-form__grid-cell-content_parties">
                <label className="parties__row flex--between vertical--bottom">
                  Ship from warehouse
                </label>
                <WarehouseFilter
                  scope="pos_create"
                  useFilter={false}
                  open={!shipFrom && state.customer && state.supplier}
                  multiple={false}
                  value={shipFrom}
                  handleChange={(value) => {
                    setWarehouse(value);
                  }}
                />

                <label className="parties__row flex--between vertical--bottom">
                  Ship To Warehouse
                </label>

                <WarehouseFilter
                  useFilter={false}
                  open={!shipTo && state.customer && state.supplier}
                  disabled={!shipFrom}
                  multiple={false}
                  value={shipTo}
                  handleChange={(value) => {
                    setWarehouseShipTo(value);
                  }}
                />
                {/* <label className="parties__row flex--between vertical--bottom">
                  Ship To Address
                </label>
                <ShiptoaddressFilter
                  // open={!state.shipToAddress && !!shipFrom}
                  businessclient={customer}
                  handleChange={handleShipTo}
                  updateList={state.showShipTo}
                /> */}
                {/* <Button
                  style={{ marginBottom: "-10px" }}
                  // circular
                  basic
                  icon="plus"
                  size="mini"
                  // className="parties__addbtn"
                  onClick={toggleAddShipTo}
                /> */}
              </div>
            </Grid.Column>
            <Grid.Column>
              {<Header as="h3">3. Confirmation</Header>}
              <div className="po-form__grid-cell-content">
                <List>
                  <List.Item>
                    {
                      <Button
                        // disabled={notComplete}
                        onClick={handleCreatePo}
                        color="teal"
                      >
                        Create PO
                      </Button>
                    }
                  </List.Item>
                </List>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={showCoForm}
          showCloseIcon={true}
          closeOnEsc={true}
          onClose={toggleCompanyModal}
          center
          classNames={{
            modal: "",
          }}
        >
          <Companyform
            company={customer}
            createCo={createCo}
            closeForm={toggleCompanyModal}
          />
        </Modal>
        {/* <Modal
          open={false}
          showCloseIcon={true}
          closeOnEsc={true}
          onClose={toggleAddShipTo}
          center
          classNames={{ modal: "" }}
        >
          <ShipToForm
            toggleAddShipTo={toggleAddShipTo}
            // addShipTo={addShipTo}
            businessclient={customer}
            closeForm={toggleAddShipTo}
          />
        </Modal> */}
      </Segment>
    </CanViewPage>
  );
};

export default CreatePO;
