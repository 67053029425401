import React, { useState } from "react";

import { DatePicker } from "rsuite";

import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import ProductFilterList from "../../Filters/ProductFilterList";
import WarehouseFilter from "../../Filters/WarehouseFilter";

import "react-datepicker/dist/react-datepicker.css";
import "./polist.scss";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};
function Filters(props) {
  const { dateTo, dateFrom, setDateTo, setDateFrom, handleSupplierChange } =
    props;

  return (
    <div>
      <div className="po-filters">
        <div className="po-filters__first-row">
          <WarehouseFilter multiple={true} style={itemStyle} scope="inboundshipments_view" />

          <BusinessclientFilter
            style={itemStyle}
            name="businessclient"
            placeholder="Customer..."
          ></BusinessclientFilter>

          {/* <ShiptoaddressFilter
                        businessclient  = {businessclient}
                        handleChange    = {handleUpdateFilter}
                        disabled        = {!businessclient}
                        value={shipToAddress}
          /> */}

          <BusinessclientFilter
            style={itemStyle}
            name="supplier"
            placeholder="Supplier..."
            useFilter={false}
            handleChange={(value) => handleSupplierChange(value)}
          ></BusinessclientFilter>

          <ProductFilterList style={itemStyle} multiple={true} />
        </div>
        <div className="flex--left">
          <div style={{ zIndex: 0 }}>
            <DatePicker
              // disabled={isLoading}
              oneTap
              value={dateFrom}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => setDateFrom(date)}
              placeholder="from..."
              style={itemStyle}
            ></DatePicker>
          </div>

          <div style={{ marginRight: "0", zIndex: 0 }}>
            <DatePicker
              // disabled={isLoading}
              oneTap
              value={dateTo}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => setDateTo(date)}
              placeholder="to..."
              style={itemStyle}
            ></DatePicker>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
