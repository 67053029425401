import React from "react";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { logOutAction, signInAction } from "../../actions/auth";
import "./Navbar.scss";
import Shoppingcard from "./shoppingcard";
import Lowstock from "./lowstock";
import LowstockNotification from "../Reports/Lowstock";

class NavbarTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datareceived: false,
      New: 0,
      toprocess: 0,
      processed: 0,
      priority: 0,

      windowWidth: null,
    };

    this.handleLogOut = this.handleLogOut.bind(this);
  }
  handleLogOut() {
    // debugger
    this.props.logOutAction();
    this.props.history.push("/signin");
  }

  render() {
    const { user, isAuthenticated, isSidebarVisible } = this.props;

    // const { windowWidth } = this.state;

    return (
      <nav className="admin__header">
        <div className="nav_logo">
          {
            isAuthenticated &&
              !user.currentUser.roles.includes("operator") &&
              // (windowWidth < 700 ? (
              (isSidebarVisible ? (
                <Icon
                  size="large"
                  name="bars"
                  onClick={this.props.hideLeftMenu}
                />
              ) : (
                <Icon
                  size="large"
                  name="bars"
                  onClick={this.props.showLeftMenu}
                />
              ))
            // ) : (
            //   <Menu inverted>
            //     <Navigation handleClick={() => {}} />
            //   </Menu>
            // ))
          }
          {/* <Link to="/signin"> Home </Link> */}
        </div>
        
        <div className="nav_right">
          <div className="nav_user flex--between vertical--center">
            {isAuthenticated && (
              <>
                {/* <CanView path="/health/errors" action='view'>
                   <Healthalert/>
                   </CanView> */}
                <Lowstock history={this.props.history}></Lowstock>

                <Shoppingcard history={this.props.history}></Shoppingcard>

                <ul>
                  <li>Hi, {user.currentUser.username}</li>

                  <li>
                    <a onClick={this.handleLogOut} className="small--text">
                      <Icon name="sign-out" /> LogOut
                    </a>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user,
  // userId: state.user.currentUser.userId
});

export default connect(mapStateToProps, { logOutAction, signInAction })(
  NavbarTop
);
